body {
  font-family: 'Roboto', sans-serif;
  color: #333C43;
}

.form-group {
  box-sizing: border-box;
  width: 100%;
}

/* .nav {
  sticky-top;
} */

.texto_bajada {
  font-size: 20px;

}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5rem;
}
.bg-light {
  background-color: #4B5761!important;
}

.navbar-light .navbar-nav .active>, .nav-item .navbar-light .navbar-nav .nav-link .active, .nav-link .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show .nav-item>.nav-link {
  color: #F8D642!important;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff!important;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .8rem 1rem;
}

.flex-footer {
  flex-shrink: 0;
}

.signature {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  color: #fff;
  font-weight: 100;
  font-size: 15px;
}

.signature .left {
  text-align: right;
}

.signature .left, .signature .right {
  flex: 1 0 auto;
}

/* path[Attributes Style] {
  fill: rgb(255, 0, 93);
  d: path("M 82.32 7.888 c -8.359 -7.671 -21.91 -7.671 -30.271 0 l -5.676 5.21 l -5.678 -5.21 c -8.357 -7.671 -21.91 -7.671 -30.27 0 c -9.404 8.631 -9.404 22.624 0 31.255 l 35.947 32.991 L 82.32 39.144 C 91.724 30.512 91.724 16.52 82.32 7.888 Z");
} */

svg:not(:root) {
    overflow: hidden;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.love svg {
  height: 1.75rem;
}

.love {
  padding: 0 .4rem;
  animation: 1.5s heartThrob infinite;
}

*, ::after, ::before {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.testimonial {
  background-color: #ECEFF3;
}

h1 {
  text-align: center;
  font-weight:100 !important;
}

.green-text {
  color: #5FE98C;
}

.green-bold-text {
  color: #4a69bd;
}

.success-text {
  color:#1AD1A8;
}

.red-text {
  color:#ff7675;
}

.grey-bg {
  background-color: #dcdde1;
}

.grey-bg2 {
  background-color: #2f3640;
}

.bg-degrade {
  background-color: #58748c;
  background-image: linear-gradient(135deg, #58748c 0%, #4b5761 41%, #15181b 87%);
}

.bg-degrade1 {
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 37%, #960365 100%);
}

.bg-degrade2 {
  background-color: #3bffd3;
  background-image: linear-gradient(147deg, #3bffd3 0%, #3268c1 37%, #403e3f 100%);
}

.bg-degrade3 {
  background-color: #3bffd3;
  background-image: linear-gradient(147deg, #3bffd3 6%, #0662f7 49%, #3d0282 92%);

}

.bg-degrade4 {
  background-color: #07eef3;
  background-image: linear-gradient(310deg, #07eef3 0%, #2AF598 100%);
}

.link_1 {
  color: #81ecec;
}

.link_2 {
  color: #74b9ff;
}

.notebook {
  width: 90%;

}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}


.btn-contact {
  box-sizing: border-box;
}

.logo_mobile {
  display: none;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #07eef3;
  background-image: linear-gradient(310deg, #07eef3 0%, #2AF598 100%);
  border: 1px solid #2AF598;
  border-radius: .25rem;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: #2AF598;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar {
  padding: 0.3rem;
}

.go-up {
  display:none;
  padding:20px;
  background:#626E79;
  opacity: 0.8;
  cursor:pointer;
  position: fixed;
  bottom:60px;
  right:20px;
  z-index:10000;
  justify-content: center;
}

.go-up-arrow {
  width: 100%;
  justify-content: center;
}



@media screen and (max-width: 42rem) {
  .display-5{
    font-size: 30px;
    margin-bottom: 10px;
  }

  .notebook {
    display: none;
  }

  .img_oops {
    width: 90%;
  }
    .logo_mobile {
        display: initial;
    }
    .logo {
        max-width: 170px;
        margin-left: 20px;
    }

    .logo {
        display: none;
    }
}

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px
);

$container-max-widths: (
  xs: 360,
  sm: 420px,
  md: 720px,
  lg: 960px
);
